<!--
 * @Descripttion :
 * @Author       : pishengjun
 * @Date         : 2021-09-24 11:10:05
 * @LastEditors  : pishengjun
 * @LastEditTime : 2022-01-02 23:40:05
 * @FilePath     : \ExamMonitorSystem.ServicePlatform.UI\src\components\MonitorView2\Extend.vue
-->

<template>
    <div class="full newMonitor">
        <monitor-view
            ref="player"
            :title="examinationSite.fullName"
            :area="examinationSite.areaName"
            :items="monitors"
            :width="width"
            :height="height"
            :count="page.count"
            :enableFil="enableFil"
        >
            <template #options>
                <v-switch class="mt-5 mx-2"  v-model="autoMute" color="success" dense>
                    <template #label>静音</template>
                </v-switch>
                <v-switch class="mt-5 mx-2"  v-model="enableFil" color="success" dense>
                    <template #label>自动填充</template>
                </v-switch>
                <v-switch class="mt-5 mx-2"
                :disabled="!canSwitch"
                v-model="carousel" color="success" dense>
                    <template #label>自动轮播</template>
                </v-switch>
                <div class="task_time">
                    <input class="time_input"
                        :disabled="Boolean(enableCarousel)"
                        v-model.number="taskTime"
                        @blur="validateTime"
                        οnkeyup="this.value=this.value.replace(/[^\d]/g,'') "
                        type="text"
                        size="small"
                        placeholder=""
                        maxlength="4" />
                    <span class="unit">秒</span>
                </div>
                <v-menu bottom dark>
                    <template #activator="{ on }">
                        <v-btn v-bind="btnProps" v-on="on">切换显示模式</v-btn>
                    </template>
                    <v-list>
                        <v-list-item-group v-model="selectedTypeIndex">
                            <v-list-item
                                v-for="(type, index) in viewType"
                                :key="index"
                            >
                                <v-list-item-icon>
                                    <v-icon
                                        color="success"
                                        v-if="selectedTypeIndex === index"
                                    >
                                        mdi-chevron-right
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    {{ type.text }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>

                <v-btn v-bind="btnProps" @click="prev">上一页</v-btn>
                <v-btn v-bind="btnProps" @click="next">下一页</v-btn>
                <slot name="options"></slot>
            </template>
        </monitor-view>
        <div v-if="showLeftMenu" class="selectTree">
            <div class="cont">
                <div class="menuBar">
                    <span v-if="showTree" @click="clickHidenTree">
                        <i class="el-icon-s-fold"></i>
                        收起
                    </span>
                    <span v-else @click="clickShowTree">
                        <i class="el-icon-s-unfold"></i>
                        展开
                    </span>
                </div>
                <el-tree
                    v-show="showTree"
                    ref="tree"
                    :data="treeData"
                    :props="defaultProps"
                    :expand-on-click-node="false"
                    :default-expanded-keys="defaultExpandedKeys"
                    :default-checked-keys="defaultCheckedKeys"
                    highlight-current
                    :current-node-key="currentNodeKey"
                    node-key="code"
                    accordion
                    @node-click="handleNodeClick"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';
import MonitorView from '@/components/MonitorView2/Index';
// import { Loading } from '@/infrastructures/decorators/Loading';
import Timer from '@/infrastructures/timer/timer';
// import Storage from '@/domain/storage';

var currentIndex = 0;
var currentGroup = 0;
var autoNextGroup = false;

export default
@Component({
    components: {
        MonitorView,
    },
})
class MonitorViewExtendComponent extends Vue {
    @Inject() notifier;
    @Inject() repository;
    @Inject() navigation;

    @Prop() width;
    @Prop() height;
    @Prop() TitleHandler;
    @Prop() PageHandler;
    @Prop() PageDataHandler;

    async mounted() {
        this.showLeftMenu = this.context.leftMenu ? true : false;
        this.autoNextGroup = this.showLeftMenu;
        // await this.GetExaminationAreaTreeData();
        if (this.showLeftMenu === true) {
            this.btnAction = 'next';
            await this.GetExaminationAreaTreeData();
        } else {
            await this.GetExaminationSiteData();
            await this.GetOnlineEquipmentCount();
            await this.GetOnlineEquipments();
        }
        this.carousel = true
        this.mutedAndPlay()
    }

    // =============== 下拉树 ==================
    showLeftMenu = false;
    showTree = true;
    defaultExpandedKeys = [];
    defaultCheckedKeys = [];
    currentNodeKey = null;
    treeData = [];
    autoMute= true
    defaultProps = {
        label: 'fullName',
        children: 'children',
        isLeaf: 'isLeaf',
    };
    currentKey = 0;
    timer = null;
    taskTime = 60;
    canSwitch = true;

    mutedAndPlay(){
        setTimeout(() => {
            console.log('mutedAndPlay', this.autoMute)
             let el = document.querySelectorAll('.vcp-player video')
                el.forEach(v=> {
                    v.muted = this.autoMute
                    v.play()
               })
        }, 3000);
    }

    @Watch('autoMute')
    async OnMuteChanged (val) {
        // console.log('autoMute' , val)
        // let el = document.querySelectorAll('.vcp-player video')
        //         el.forEach(v=> {
        //             v.muted = val
        // })
        if(val) {
            this.$refs.player.muted()
        } else {
            this.$refs.player.unmuted()
        }
    }

    clickShowTree() {
        this.showTree = true;
    }

    clickHidenTree() {
        this.showTree = false;
    }

    // 递归
    LoopFn(array) {
        for (let i = 0; i < array.length; i++) {
            const el = array[i];
            array[i].fullName = `${el.code}-${el.name}`;
            if (el.children && Array.isArray(el.children)) {
                el.children = el.children.map((val, cIndex) => {
                    val.index = el.index;
                    val.cIndex = cIndex;
                    return val;
                });
                el.children = this.LoopFn(el.children);
            }
        }
        return array;
    }
    // 重新初始化载入数据
    async initSiteFn(areacode, cIndex) {
        await this.GetExaminationSiteData(
            { code: '', areacode: areacode, type: 1 },
            cIndex
        );
        await this.GetOnlineEquipmentCount();
        await this.GetOnlineEquipments();
        // if(autoNextGroup===true){
        //     type==='prev'?this.prev() : this.next()
        // }
    }

    // 获取数所有节点
    async GetExaminationAreaTreeData() {
        currentIndex = 0;
        currentGroup = 0;
        let roomType = localStorage.getItem('roomType') || -1;
        await this.repository.ExaminationDistrict.GetExaminationAreaTree(
            this.context.examId,
            roomType
        ).then((res) => {
            res.data = res.data.map((val, index) => {
                val.index = index;
                return val;
            });
            this.treeData = this.LoopFn(res.data);
        });
        await this.initSiteFn(this.treeData[0].code);
    }

    // 点击数节点
    async handleNodeClick(node) {
        console.log('点击数节点===>',node.index, node.cIndex, node,);
        const { level, code, index, cIndex, pCode } = node;
        currentGroup = index || 0;
        currentIndex = cIndex;
        autoNextGroup = false;
        if (level == 1) {
            await this.initSiteFn(code);
        } else if (level == 2) {
            await this.initSiteFn(pCode, cIndex);
            currentIndex = cIndex;
        }
    }

    // =============== 下拉树 END ==================

    async GetOnlineEquipmentCount() {
        if (!this.PageHandler) return;
        let count = await this.PageHandler(this.page);
        this.page.count = count;
    }

    async GetOnlineEquipments() {
        if (!this.PageDataHandler) return;
        this.monitors.length = 0;
        // this.monitors = []
        this.monitors = (await this.PageDataHandler(this.page)) || [];
        console.log('this.monitors======>',this.monitors)
        await this.sleep(1000);
        if (
            !this.monitors ||
            (Array.isArray(this.monitors) && this.monitors.length === 0)
        ) {
            // if(this.examinationSite){console.log(`【${this.examinationSite.areaName}-${this.examinationSite.name}】当前考点无数据0条，继续请求...`) }
            this.btnAction && this.LoadPageData();
        } else {
            // if(this.examinationSite){console.log(`【${this.examinationSite.areaName}-${this.examinationSite.name}】当前考点数据${this.page.count}条：`,JSON.parse(JSON.stringify(this.monitors)), JSON.parse(JSON.stringify(this.page)))}
            this.isEmpty = false;
        }
    }

    async GetExaminationSiteData(param, cIndex = 0) {
        if (!this.TitleHandler) return;
        this.examinationSites = await this.TitleHandler(param);
        if (this.examinationSites.length > 0) {
            this.examinationSites = this.LoopFn(this.examinationSites);
            this.examinationSite = this.examinationSites[cIndex];
            this.page.code = this.examinationSite
                ? this.examinationSite.code
                : ''; //更新为最新的code
        }
        // await this.sleep(3000)
    }

    prev() {
        autoNextGroup = true;
        this.btnAction = 'prev';
        this.LoadPageData();
    }

    next() {
        autoNextGroup = true;
        this.btnAction = 'next';
        this.LoadPageData();
    }

    async VideoCarousel() {
        this.currentKey++;
        await this.LoadPageData();
    }

    validateTime(event) {
       const value = event.target.value
       if (Number.isNaN(Number(value))) {
            this.carousel = false
            this.notifier.warn('请输入的数字!');
            this.canSwitch = false
            // return;
        } else if (Number(value) < 30) {
            this.taskRefresh = false;
            this.notifier.warn('请输入大于30的数字');
            this.canSwitch = false
        } else {
            this.canSwitch = true
        }
    }

    sleep(timeout) {
        return new Promise(
            (resolve) => (this.timer = setTimeout(() => resolve(true), timeout))
        );
    }

    // @Loading('数据加载中')
    async LoadPageData() {
        // ============== 切换监控视频轮查 ==================
        // console.log('+++++++开始切换监控视频轮查examinationSites===>',this.examinationSites)
        // console.log('currentGroup===>',currentGroup,'currentIndex==>',currentIndex)
        this.examinationSites = this.LoopFn(this.examinationSites);
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }

        // 上一页
        if (this.btnAction == 'prev') {
            console.log(
                '上一页',
                'currentGroup',
                currentGroup,
                'currentIndex',
                currentIndex
            );
            // 【判断是否到达最后一个考区，终止轮查，以防无限循环轮查】
            if (currentGroup == 0 && currentIndex <= 0) {
                this.notifier.warn('已经是第一个考点!');
                // console.log(`当前终止执行，原因：已到达第一个考区`)
                this.enableCarousel = false;
                return false;
            }

            // 判断当前考点的分页是否小于1
            if (this.page.cur <= 1) {
                currentIndex = currentIndex <= 0 ? 0 : currentIndex - 1;
                // console.log('prev',currentIndex, this.examinationSite)
                this.examinationSite = this.examinationSites[currentIndex];
                this.page.code = this.examinationSite
                    ? this.examinationSite.code
                    : '';
                this.page.cur = 1;
                // console.log("当前执行:切换上一个考点",this.page)
                // 判断是否到达第一个考点
                if (currentIndex == 0) {
                    // this.notifier.warn('已经是第一个考点!');
                    // console.log(`当前执行切换考区：已到达当前考区第一个考点`,'prev',currentIndex)
                    if (currentGroup < this.treeData.length) {
                        if (currentGroup > 0) {
                            currentGroup--;
                            // console.log(`即将切换考区${currentGroup}：`,this.treeData[currentGroup])
                            await this.initSiteFn(
                                this.treeData[currentGroup].code,
                                'prev'
                            );
                        } else {
                            this.initSiteFn(this.treeData[0].code, 'prev');
                        }
                    }
                    return false;
                }
            } else {
                this.page.cur--;
                // console.log("当前执行:切换当前考点的上一页",this.page)
            }
        }
        // 下一页
        else if (this.btnAction == 'next') {
            console.log(
                '下一页',
                'currentGroup',
                currentGroup,
                'currentIndex',
                currentIndex
            );
            // 判断当前考点的分页是否大于总数
            const pagination = Math.ceil(this.page.count / this.page.size);
            if (this.page.cur >= pagination) {
                if (currentIndex >= this.examinationSites.length - 1) {
                    // console.log('已经是当前组最后一个考点',currentGroup,currentIndex)
                    // 自动轮播模式
                    if (this.isAuto === true) {
                        // console.log('自动轮播模式');
                        currentGroup =
                            currentGroup >= this.treeData.length - 1
                                ? 0
                                : currentGroup + 1;
                        currentIndex =
                            currentIndex == this.examinationSites.length - 1
                                ? -1
                                : currentIndex + 1;
                        // console.log('自动轮播模式',autoNextGroup,currentGroup,currentIndex)
                        await this.initSiteFn(
                            this.treeData[currentGroup].code,
                            'next'
                        );
                        return false;
                    }
                    // 非自动轮播模式
                    else {
                        // console.log('非自动轮播模式');
                        // 考区模式轮播
                        if (
                            autoNextGroup === true &&
                            currentGroup < this.treeData.length - 1
                        ) {
                            currentGroup++;
                            currentIndex = -1;
                            // console.log('考区模式轮播');
                            // console.log('手动下一页模式',autoNextGroup,currentGroup,currentIndex)
                            // console.log(this.treeData,this.treeData[currentGroup].code)
                            await this.initSiteFn(
                                this.treeData[currentGroup].code,
                                'next'
                            );
                        }
                        // 考区模式到达最后一个考点
                        if (
                            autoNextGroup === true &&
                            currentGroup + 1 == this.treeData.length &&
                            currentIndex + 1 == this.examinationSites.length
                        ) {
                            this.notifier.warn('已经到达最后一个考点!');
                            return false;
                        }
                    }
                    return false;
                }
                currentIndex =
                    currentIndex == this.examinationSites.length - 1
                        ? 0
                        : currentIndex + 1;
                // console.log('next',currentIndex, this.examinationSite)
                this.examinationSite = this.examinationSites[currentIndex];
                this.page.code = this.examinationSite.code;
                this.page.cur = 1;
                // console.log("当前执行:切换下一个考点",currentIndex,this.examinationSite.name,this.page)
            } else {
                this.page.cur++;
                // console.log("当前执行:切换当前考点的下一页",JSON.parse(JSON.stringify(this.page)))
            }
        } else {
            // console.log('当前执行：刷新模式',this.examinationSites)
            if (this.examinationSites && Array.isArray(this.examinationSites)) {
                this.examinationSite = this.examinationSites[currentIndex];
                if (this.examinationSite) {
                    this.page.code = this.examinationSite.code;
                    this.page.cur = 1;
                }
            }
        }
        // await this.sleep(3000)
        await this.GetOnlineEquipmentCount(this.page);
        await this.GetOnlineEquipments();
    }

    @Watch('examinationSite')
    setTreeKey() {
        // console.log('检测到this.examinationSite改变了==>',this.examinationSite)
        this.$nextTick(() => {
            this.defaultExpandedKeys = [this.examinationSite.code];
            this.defaultCheckedKeys = [this.examinationSite.code];
            this.$refs.tree.setCurrentKey(this.examinationSite.code);
        });
    }


    @Watch('carousel')
    getGarousel(val){
        this.enableCarousel = val
    }

    @Watch('enableCarousel')
    async OnCarouselStatusChanged(val) {
        this.isAuto = val;
        // if(this.carouselTimer){
        //     this.carouselTimer.Stop()
        // } else {
        //     this.carouselTimer = Timer.CreateFromSecond(this.VideoCarousel, this.taskTime)
        // }
        if (val) {
            this.carouselTimer = Timer.CreateFromSecond(this.VideoCarousel, this.taskTime)
            this.btnAction = 'next';
            this.autoNextGroup = true;
            this.carouselTimer.Start();
            // this.carouselTimer.Start();
        } else {
            this.btnAction = null;
            if (this.carouselTimer) this.carouselTimer.Stop();
            this.carouselTimer = null
        }
    }

    @Watch('selectedTypeIndex',{immediate: true,})
    OnViewTypeChanged() {
        let type = this.viewType[this.selectedTypeIndex];
        let isFirstPage = this.page.cur === 1;
        this.page.size = type.value;
        this.page.cur = 1;
        if (isFirstPage) {
            this.btnAction = 'update';
            this.LoadPageData();
        }
    }

    examinationSite = {};
    monitors = [];
    isEmpty = true; //全局是否为空
    isAuto = false; //是否自动轮播
    btnAction = null; //默认
    page = {
        cur: 1,
        count: 1,
        size: 4,
    };

    btnProps = {
        color: 'white',
        outlined: true,
        class: 'mr-2',
    };
    selectedTypeIndex = 1;
    viewType = [
        { text: '单个', value: 1 },
        { text: '四宫格', value: 4 },
        { text: '六宫格', value: 6 },
        { text: '九宫格', value: 9 },
    ];
    enableCarousel = false;
    carousel = false;
    enableFil = true
    // 切换时间N分钟
    carouselTimer = null;
}
</script>

<style lang="scss" scoped>
.task_time {
    position: relative;
    .time_input {
    width: 80px;
    border-radius: 5px;
    border: 1px solid #fff;
    font-size: 14px;
    color: #fff;
    padding-left: 10px;
    // margin-top: 20px;
    height: 36px;
    margin-right: 5px;
}
    .unit {
        position: absolute;
        right: 6px;
        top: 6px;
    }
}
.full {
    width: 100%;
    height: 100%;
    position: relative;
}
.newMonitor {
    .selectTree {
        position: absolute;
        z-index: 10;
        left: 0;
        top: 48px;
        max-height: calc(100vh - 48px);
        overflow-x: hidden;
        overflow-y: auto;
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        // min-width: 160px;
        ::v-deep .el-tree {
            background: none;
            color: rgba(255, 255, 255, 0.85);
            font-size: 16px;
            line-height: 36px;
            border-top: 1px solid rgba(255, 255, 255, 0.1);
            padding: 10px;
            .el-tree-node__label {
                font-size: 16px;
            }
            .el-menu-item.is-active {
                background-color: rgb(201, 188, 80) !important;
            }
            .el-tree-node__content {
                height: 36px;
                padding-right: 10px;
                &:hover {
                    // background: #89c2f7;
                    background: rgba(255, 255, 255, 0.1);
                }
            }
            .el-tree-node:focus > .el-tree-node__content {
                // background-color: #5daaf0;
                background: rgba(93, 170, 240, 0);
            }
            .el-tree-node.is-current > .el-tree-node__content {
                // background: #5daaf0;
                background: rgba(93, 170, 240, 0.2);
            }
        }
        .cont {
            // padding: 10px;
            background: rgba(255, 255, 255, 0.08);
            .menuBar {
                position: sticky;
                left: 0;
                top: 0;
                right: 0;
                z-index: 10;
                background: rgba(0, 0, 0, 0.9);
                padding: 8px 15px;
                span {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>

<style scoped>
/* 滚动条样式 */
/*Width*/
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/*Track*/
::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
}

/*Handle*/
::-webkit-scrollbar-thumb {
    background-color: #11171a;
    border-radius: 15px;
}
</style>
